const defaultModule = {
  namespaced: true,
  state: {
    name: "",
    code: "",
    expireTm: 0,
  },
  mutations: {
    SET_LEAGUE_CODE(state, newLeagueCode) {
      state.code = newLeagueCode;
    },
    SET_LEAGUE_NAME(state, newLeagueName) {
      state.name = newLeagueName;
    },
    SET_TOKEN_EXPIRE_TIME(state, newExpireTime) {
      state.expireTm = newExpireTime;
    },
  },
  actions: {},
};

export default defaultModule;
