import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import livePanelRoutes from "./livePanel/livePanel-route.js";

const routes = [
  {
    path: "/live/:token/name/:name",
    name: "LivePanel",
    component: DefaultLayout,
    meta: { requireAuth: false },
    children: [...livePanelRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
