import { h, resolveComponent } from "vue";
import LivePanelIndex from "@/views/livePanel/LivePanel.vue";

const livePanelRoutes = [
  {
    path: "",
    name: "LivePanelRoute",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "LivePanelIndex",
        component: LivePanelIndex,
      },
    ],
  },
];

export default livePanelRoutes;
