import { onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import {
  Room,
  RoomEvent,
  MediaDeviceFailure,
  createLocalTracks,
  LocalParticipant,
  VideoPreset,
  // VideoPresets,
  Track,
  ConnectionState,
  ConnectionQuality,
} from "livekit-client";
const moment = require("moment");
const MyVideoPresets = {
  h360: new VideoPreset(640, 360, 300000, 12),
  h480: new VideoPreset(854, 480, 600000, 12),
  h720: new VideoPreset(1280, 720, 1700000, 12),
  h1080: new VideoPreset(1920, 1080, 3000000, 25),
  h1440: new VideoPreset(2560, 1440, 5000000, 25),
};

export function useLivekit() {
  const store = useStore();
  const route = useRoute();
  const state = reactive({
    videoModel: ref(null),
    audioModel: ref(null),
    codecModel: ref({ label: "VP8", value: "vp8" }),
    resolutionModel: ref({ label: "h720", value: "h720" }),
    textareaModel: "",
    livekitToken: null,
    vdoOptions: [],
    audioOptions: [],
    codecOptions: [
      { label: "VP8", value: "vp8" },
      { label: "H.264", value: "h264" },
      { label: "AV1", value: "av1" },
    ],
    resolutionOptions: [
      { label: "h360", value: "h360" },
      { label: "h480", value: "h480" },
      { label: "h720", value: "h720" },
      { label: "h1080", value: "h1080" },
      { label: "h1440", value: "h1440" },
    ],
    enableCamera: true,
    enableAudio: true,
    enableAdaptiveStream: false,
    isConnected: false,
    currentRoom: null,
    loading: ref(false),
    bitrateInterval: undefined,
    staffName: "",
    connectionQuality: null,
    bitrate: "",
    connectionClassName: "",
    leagueName: route.params.name ? route.params.name : "",
    unHidePanel: false,
  });
  watch(
    () => [
      state.enableCamera,
      state.enableAudio,
      state.videoModel,
      state.audioModel,
      state.resolutionModel,
    ],
    async (
      [cameraEnable, audioEnable, cameraInput, audioInput, res],
      [prevCamera, prevAudio, prevCameraInput, prevAudioInput, preRes]
    ) => {
      if (cameraEnable !== prevCamera) {
        if (!cameraEnable) {
          appendStaffConsole("disabling video");
        } else {
          appendStaffConsole("enabling video");
        }
        if (state.currentRoom !== null)
          await state.currentRoom.localParticipant.setCameraEnabled(
            cameraEnable
          );
      }
      if (audioEnable !== prevAudio) {
        if (!audioEnable) {
          appendStaffConsole("disabling audio");
        } else {
          appendStaffConsole("enabling audio");
        }
        if (state.currentRoom !== null)
          await state.currentRoom.localParticipant.setMicrophoneEnabled(
            audioEnable
          );
      }
      if (state.isConnected) {
        const videoPub = state.currentRoom.localParticipant.getTrack(
          Track.Source.Camera
        );
        if (res !== preRes) {
          console.log("a");
          if (!videoPub) {
            return;
          }
          const options = {
            resolution: MyVideoPresets[res].resolution,
            deviceId: cameraInput,
          };
          await videoPub.videoTrack?.restartTrack(options);
          appendStaffConsole(`Resolution changed to ${res}`);
        }
        if (cameraInput !== prevCameraInput) {
          console.log("b");
          const options = {
            resolution: MyVideoPresets[res].resolution,
            deviceId: cameraInput,
          };
          // console.log('res ',res);
          // console.log('state.resolutionModel ',state.resolutionModel);
          // console.log('state.videoModel ',state.videoModel);
          // console.log('cameraInput ',cameraInput);
          // console.log('options ',options);
          // await state.currentRoom.switchActiveDevice("videoinput", cameraInput);
          await videoPub.videoTrack?.restartTrack(options);
          appendStaffConsole(`Camera input changed to ${cameraInput}`);
        }
        if (audioInput !== prevAudioInput) {
          console.log("c");
          await state.currentRoom.switchActiveDevice("audioinput", audioInput);
          appendStaffConsole(`Audio input changed to ${audioInput}`);
        }
      }
    }
  );
  onMounted(async () => {
    await getInputDevices("video", state.vdoOptions);
    state.videoModel = state.vdoOptions[0].value;
    await getInputDevices("audio", state.audioOptions);
    state.audioModel = state.audioOptions[0].value;

    state.codecModel = "vp8";
    // state.resolutionModel = { label: "h720", value: "h720" };
    state.resolutionModel = "h720";
  });
  onBeforeMount(async () => {
    await extractLiveToken();
  });
  const getInputDevices = async (kind, options) => {
    appendStaffConsole(`Fetching ${kind}.`);
    const type = `${kind}input`;
    const devices = await Room.getLocalDevices(type);
    appendStaffConsole(`Successfully fetched ${kind} input.`);
    for (let i = 0; i < devices.length; i++) {
      let tmp = {
        label: devices[i]["label"],
        value: devices[i]["deviceId"],
      };
      options.push(tmp);
    }
  };
  const extractLiveToken = async () => {
    state.livekitToken = route.params.token;
    const jwt = parseJwt(state.livekitToken);
    state.staffName = jwt.name;
    store.commit("defaultModule/SET_LEAGUE_CODE", jwt.name);
    store.commit("defaultModule/SET_LEAGUE_NAME", state.leagueName);
    store.commit("defaultModule/SET_TOKEN_EXPIRE_TIME", jwt.exp);
    if (jwt.exp > parseInt(moment().format("X"))) {
      state.unHidePanel = true;
      console.log("token expired from live panel");
    }
  };
  const connectToLivekit = async () => {
    state.loading = true;
    let roomOptions = {
      audioCaptureDefaults: {
        deviceId: state.audioModel,
      },
      videoCaptureDefaults: {
        resolution: MyVideoPresets[state.resolutionModel].resolution,
        deviceId: state.videoModel,
      },
    };
    const url = process.env.VUE_APP_LIVEKIT_URL
      ? process.env.VUE_APP_LIVEKIT_URL
      : "wss://webrtc.pplotto.com";
    state.currentRoom = new Room({
      adaptiveStream: state.enableAdaptiveStream,
      dynacast: false,
      publishDefaults: {
        simulcast: true,
        // videoSimulcastLayers: [VideoPresets.h180, VideoPresets.h360],
        // videoSimulcastLayers: [MyVideoPresets.h360, MyVideoPresets.h720],
        videoSimulcastLayers: [MyVideoPresets.h360],
        videoCodec: state.codecModel.value || "vp8",
      },
      videoCaptureDefaults: {
        resolution: MyVideoPresets[state.resolutionModel].resolution,
      },
    });
    console.log(MyVideoPresets[state.resolutionModel].resolution);
    // console.log(MyVideoPresets.h90.resolution);
    console.log(state.currentRoom);
    state.currentRoom
      .on(RoomEvent.Disconnected, handleRoomDisconnect)
      .on(RoomEvent.Reconnected, () => {
        appendStaffConsole(
          "Successfully reconnected. server" +
            state.currentRoom.engine.connectedServerAddress
        );
      })
      .on(RoomEvent.Reconnecting, () =>
        appendStaffConsole("Reconnecting to room")
      )
      .on(RoomEvent.SignalConnected, async () => {
        appendStaffConsole("Successfully connected to livekit server.");
        state.loading = false;
        state.isConnected = true;
      })
      .on(RoomEvent.MediaDevicesError, (e) => {
        const failure = MediaDeviceFailure.getFailure(e);
        appendStaffConsole("media device failure" + failure);
      })
      .on(RoomEvent.LocalTrackPublished, () => {
        console.log("LocalTrackPublished");
        appendStaffConsole("LocalTrackPublished");
      })
      .on(RoomEvent.LocalTrackUnpublished, () => {
        console.log("LocalTrackUnpublished");
        appendStaffConsole("LocalTrackUnpublished ");
      })
      .on(RoomEvent.MediaDevicesChanged, () => {
        console.log("sdsdsdsdsdsd");
        appendStaffConsole("media device  changed ");
      });
    try {
      appendStaffConsole("Connect to livekit server.");

      await state.currentRoom.connect(url, state.livekitToken, roomOptions);
      // console.log(`connect with options ${roomOptions}`);
      // console.log(roomOptions);
      const tracks = await createLocalTracks({
        audio: { deviceId: state.audioModel },
        video: {
          deviceId: state.videoModel,
          resolution: MyVideoPresets[state.resolutionModel].resolution,
        },
      });
      const volumeSlider = document.getElementById(`volume-control`);
      volumeSlider.addEventListener("input", (ev) => {
        state.currentRoom.localParticipant.setVolume(
          Number.parseFloat(ev.target.value)
        );
      });
      for (let track of tracks) {
        await state.currentRoom.localParticipant.publishTrack(track);
        if (track.kind === "video") {
          const v = document.getElementById("livekitPlayer");
          track.attach(v);
        }
      }
      state.bitrateInterval = setInterval(renderBitrate, 1000);
    } catch (error) {
      let message = error;
      if (error.message) {
        message = error.message;
        appendStaffConsole("could not connect : " + message);
      }
      return;
    }
  };

  const handleRoomDisconnect = async (reason) => {
    if (!state.currentRoom) return;
    // console.log(reason);
    appendStaffConsole("Disconnected.");
    appendStaffConsole({ reason });
    state.loading = false;
    state.isConnected = false;
    state.currentRoom.participants.forEach((participant) => {
      // renderParticipant(p, true);
      const cameraPub = participant.getTrack(Track.Source.Camera);
      // const micPub = participant.getTrack(Track.Source.Microphone);
      if (participant instanceof LocalParticipant) {
        // if (cameraPub?.videoTrack) {
        // detach manually whenever possible
        cameraPub.detach(document.getElementById("livekitPlayer"));
        // }
      }
    });
  };
  const disconnectFromRoom = async () => {
    await state.currentRoom.disconnect();
    if (state.bitrateInterval) {
      clearInterval(state.bitrateInterval);
    }
  };

  const appendStaffConsole = (message) => {
    if (typeof message === "object") {
      state.textareaModel +=
        getLogTime() + "    " + `${JSON.stringify(message)} ` + " \n";
    } else {
      state.textareaModel += getLogTime() + "    " + message + " \n";
    }
  };
  const getLogTime = () => {
    let time = moment().format("HH:mm:ss");
    let rt = "[" + time + "]";
    return rt;
  };

  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };
  const renderBitrate = () => {
    if (
      !state.currentRoom ||
      state.currentRoom.state !== ConnectionState.Connected
    ) {
      return;
    }
    const participants = [...state.currentRoom.participants.values()];
    participants.push(state.currentRoom.localParticipant);

    for (const p of participants) {
      const v = document.getElementById("livekitPlayer");
      if (p.identity !== state.staffName) continue;
      let totalBitrate = 0;
      for (const t of p.tracks.values()) {
        if (t.track) {
          totalBitrate += t.track.currentBitrate;
        }
      }
      if (totalBitrate > 0) {
        state.bitrate = `${Math.round(
          totalBitrate / 1024
        ).toLocaleString()} kbps , (${v.videoWidth}x${v.videoHeight})`;
      }
      switch (p.connectionQuality) {
        case ConnectionQuality.Excellent:
        case ConnectionQuality.Good:
        case ConnectionQuality.Poor: {
          state.connectionQuality = p.connectionQuality;
          // const signalElm = document.getElementById(`signal-quality`);
          state.connectionClassName = `connection-${p.connectionQuality}`;
          break;
        }
        default:
          state.connectionQuality = null;
      }
    }
  };
  return { state, connectToLivekit, disconnectFromRoom };
}
