<template>
  <q-layout
    view="hhh lpR lff"
    style="width: 100%; margin-left: auto; margin-right: auto"
  >
    <q-header bordered class="header">
      <q-toolbar>
        <q-img
          class="muaysod_logo"
          src="@/assets/images/muay_logo.png"
          style="min-width: 240px; max-width: 240px"
        />

        <q-space class="muaysod_logo" />
        <q-btn
          :color="buttonColor"
          :text-color="textColor"
          align="middle"
          style="font-size: 0.8rem"
          v-if="remainTime !== ''"
        >
          {{ expireTm }}
          <q-badge
            v-if="!isTokenExpired && remainTime !== ''"
            style="font-size: 0.6rem"
            :color="floatColor"
            floating
            >{{ remainTime }}</q-badge
          ></q-btn
        >
        <q-space />
        <q-badge
          color="white"
          text-color="black"
          align="middle"
          class="q-pa-sm"
        >
          <q-icon name="live_tv" :size="'xs'" />&nbsp; {{ leagueCode }}
          {{ leagueName }} </q-badge
        >&emsp;
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted, computed } from "@vue/runtime-core";
import { useStore } from "vuex";
const moment = require("moment");
export default {
  name: "LayoutDefault",
  setup() {
    // const router = useRouter();
    const store = useStore();
    const leagueCode = computed(() => store.state.defaultModule.code);
    const leagueName = computed(() => store.state.defaultModule.name);
    const state = reactive({
      code: "",
      web: "",
      expireTm: "",
      expireInterval: "",
      remainTime: "",
      isTokenExpired: false,
      buttonColor: "white",
      textColor: "black",
      floatColor: "green",
    });

    onMounted(() => {
      const exp = store.state.defaultModule.expireTm;
      state.expireTm = `EXPIRE @ ${moment(exp, "X").calendar()}`;
      state.expireInterval = setInterval(() => {
        state.remainTime = moment().to(moment(exp, "X"));
        const msg = state.remainTime;
        if (msg.match(/.*ago$/g)) {
          state.expireTm = `Token Expired`;
          clearExpireInterval();
          state.isTokenExpired = true;
          state.buttonColor = "negative";
          state.textColor = "white";
        } else if (msg.match(/.*minutes$/i)) {
          state.floatColor = "orange";
        } else if (msg.match(/.*minute$/i)) {
          state.floatColor = "red";
        }
      }, 1000);
    });
    const clearExpireInterval = () => {
      if (state.expireInterval) {
        clearInterval(state.expireInterval);
      }
    };

    return {
      leagueCode,
      leagueName,
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .muaysod_logo {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
</style>
