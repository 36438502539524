<template>
  <q-page v-if="unHidePanel" class="q-pa-md custom-page">
    <div class="row">
      <div class="col-12 col-md-6">
        <video
          id="livekitPlayer"
          style="width: 100%"
          autoplay
          muted
          playsinline
        ></video>
        <audio id="livekitAudio">
          <div class="volume-control">
            <input
              id="volume-control"
              type="range"
              min="0"
              max="1"
              step="0.1"
              value="1"
              orient="vertical"
            />
          </div>
        </audio>
        <div v-if="isConnected">
          <div class="row">
            <div class="col-6" style="text-align: left" v-if="bitrate != ''">
              <span>Bitrate : {{ bitrate }}</span>
            </div>
            <div
              class="col-6"
              style="text-align: right"
              v-if="connectionQuality != null"
            >
              connection quality :
              <span :class="connectionClassName"> {{ connectionQuality }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row" style="min-height: 20%">
          <div class="col-6 col-md-3 q-pa-sm">
            <label for="VideoInput" style="width: 100%; height: 20%"
              >Video Input</label
            >
            <select
              v-model="videoModel"
              id="VideoInput"
              style="width: 100%; height: 80%"
            >
              <option
                v-for="option in vdoOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="col-6 col-md-3 q-pa-sm">
            <label for="AudioInput" style="width: 100%; height: 20%"
              >Audio Input</label
            >
            <select
              v-model="audioModel"
              id="AudioInput"
              style="width: 100%; height: 80%"
            >
              <option
                v-for="option in audioOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="col-6 col-md-3 q-pa-sm">
            <!-- <q-select
              filled
              v-model="codecModel"
              :options="codecOptions"
              label="Video Codec"
            ></q-select> -->

            <label for="VideoCodec" style="width: 100%; height: 20%"
              >Video Codec</label
            >
            <select
              v-model="codecModel"
              id="VideoCodec"
              style="width: 100%; height: 80%"
            >
              <option
                v-for="option in codecOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="col-6 col-md-3 q-pa-sm">
            <!-- <q-select
              filled
              v-model="resolutionModel"
              :options="resolutionOptions"
              label="Video Quality"
            ></q-select> -->

            <label for="VideoQuality" style="width: 100%; height: 20%"
              >Video Quality</label
            >
            <select
              v-model="resolutionModel"
              id="VideoQuality"
              style="width: 100%; height: 80%"
            >
              <option
                v-for="option in resolutionOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row q-gutter-md q-pa-sm" style="min-height: 10%">
          <q-toggle v-model="enableCamera">เปิดใช้งานกล้อง</q-toggle>
          <q-toggle v-model="enableAudio">เปิดใช้งาน Mic</q-toggle>
          <q-toggle v-model="enableAdaptiveStream"
            >เปิดใช้งาน adaptiveStream</q-toggle
          >

          <q-btn
            :loading="loading"
            v-if="!isConnected"
            color="primary"
            @click="connectToLivekit()"
            :disabled="audioModel === null || videoModel == null"
            class="q-pa-md"
          >
            เริ่มถ่ายทอดสด
            <template v-slot:loading>
              <q-spinner-radio class="on-left"></q-spinner-radio>
              กำลังเชื่อมต่อ
            </template>
          </q-btn>

          <q-btn
            :disable="!loading && !isConnected"
            v-if="isConnected"
            color="negative"
            class="q-pa-md"
            @click="disconnectFromRoom()"
            label="ยกเลิก"
          ></q-btn>
          <!-- </div> -->
        </div>
        <div class="row q-gutter-md q-pa-sm" style="min-height: 70%">
          <textarea class="col-12" v-model="textareaModel" readonly></textarea>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useLivekit } from "./composable/use-livekit";
export default {
  name: "LivePanel",
  setup() {
    const { state, connectToLivekit, disconnectFromRoom } = useLivekit();
    return { ...toRefs(state), connectToLivekit, disconnectFromRoom };
  },
};
</script>

<style lang="scss" scoped>
.card-sector {
  font-family: "JamjureeMedium";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  & .dashboard-card {
    text-align: center;
    font-size: 40px;
  }

  .my-select {
    max-height: 100%;
    max-width: 200%;
  }
}
</style>
